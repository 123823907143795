import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import logo from "../images/SiteLogo5px.png"

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
          <img src={logo} alt="Logo" className="logoIcon" />
        </div>
        <div className="content">
            <div className="inner">
                <h1>Nakatomi Print Labs</h1>
            </div>
        </div>
        <nav>
            <ul>
            <li><Link to="/services">Products & Services</Link></li>
            <li><Link to="/clients">Clients</Link></li>
            <li><Link to="/team">Team</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
