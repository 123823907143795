import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
    <ul className="icons">
      <li><a href="http://www.facebook.com/nakatomiinc" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
      <li><a href="http://www.instagram.com/nakatomi_inc" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
    </ul>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
